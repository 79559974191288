import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { query, collection, where, or, and } from "@firebase/firestore";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { Group, LiteGroup } from "@converge-collective/common/models/Group";
import { converters } from "~/lib/converter";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { WithRef } from "@converge-collective/common/models/Base";
import { useNetworkMembership } from "./useNetworkMembership";
import { sortBy } from "lodash";

export function useMyGroups(): {
  groups: WithRef<Group>[];
  unreadMessages: Record<string, number> | undefined;
  status: "loading" | "error" | "success";
  networkMembership: LiteGroup[];
} {
  const firestore = useFirestore();

  // My Config
  const { profile, network } = useLoggedInState();
  const { networkMembership } = useNetworkMembership({ profile, network });

  // Firebase query
  const myGroupsQuery = query(
    (network && profile
      ? query(
          collection(network.ref, "groups"),
          and(
            where("memberIds", "array-contains", profile.id),
            or(
              where("latestDocUpdate.status", "==", DocStatuses.Published),
              where("latestDocUpdate.status", "==", DocStatuses.Active)
            )
          )
        )
      : collection(firestore, "noop")
    ).withConverter(converters.group.read)
  );
  // Firestore data
  const { data: groups = [], status } =
    useFirestoreCollectionData(myGroupsQuery);

  // Unread Messages
  const unreadMessages = networkMembership?.groupUnreadCount;

  return {
    groups,
    unreadMessages,
    status,
    networkMembership: networkMembership
      ? sortBy(Object.values(networkMembership.groupMemberships || {}), "name")
      : [],
  };
}
