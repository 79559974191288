import { WithRef } from "@converge-collective/common/models/Base";
import { Group as IGroup } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import { Lock } from "@mui/icons-material";
import Group from "@mui/icons-material/Group";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { MenuItem } from "react-pro-sidebar";
import { groupDetailRoute } from "~/routes";

export function GroupMenuItem({
  group,
  network,
  unreadCount,
}: {
  group: WithRef<IGroup>;
  network: WithRef<Network>;
  unreadCount: number;
}) {
  const router = useRouter();

  const privateIcon = (
    <Tooltip title="Private Group">
      <Lock color="primary" />
    </Tooltip>
  );

  const photoIcon = (
    <img
      style={{
        width: 30,
        height: 30,
        objectFit: "cover",
        borderRadius: 5,
      }}
      alt={group.name}
      src={group.photoUrl ?? ""}
    />
  );

  const icon = group.isPrivate ? (
    privateIcon
  ) : group.photoUrl ? (
    photoIcon
  ) : (
    <Group />
  );

  return (
    <MenuItem
      icon={icon}
      onClick={() => router.push(groupDetailRoute(network.slug, group.slug))}
      key={group.id}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      suffix={
        unreadCount > 0 ? (
          <Chip label={unreadCount} size="small" color="primary" />
        ) : null
      }
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tooltip title={group.name}>
          <Typography
            sx={{
              fontWeight: unreadCount > 0 ? "bold" : "normal",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "90%",
            }}
            flex={1}
            display={"inline-block"}
          >
            {group.name}
          </Typography>
        </Tooltip>
      </Stack>
    </MenuItem>
  );
}
